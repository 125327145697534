// Récupération de la valeur de la constante NO_LAZYLOAD pour appliquer le paramètre aux swiper
var isLazyLoad = (typeof is_lazy_load !== 'undefined' && is_lazy_load==false) ? false : true;

$(function() {

    // Gestion du shad pour le menu
    $(".my_account.is_logged").on("mouseenter", function() {
        $(this).addClass('hover');
        $("#shad_menu").addClass("actif");
    });

    $(".my_account.is_logged").on("mouseleave", function() {
        $(this).removeClass('hover');
        $("#shad_menu").removeClass("actif");
    });

    $(".my_basket").on("mouseenter", function() {
        topCartAppear();
    });

    $(".my_basket").on("mouseleave", function() {
        topCartDesappear();
    });

    var menu_timeout = setTimeout(null);

    $(".main_menu_itm")
        .not(".menu_itm_hover")
        .on({
            mouseenter: function() {
                var mainmenu = $(".main_menu");

                clearTimeout(menu_timeout);

                mainmenu.addClass("actif");
            },
            mouseleave: function() {
                menuItemClose();
            },
            touchstart: function() {
                var mainmenu = $(".main_menu");

                clearTimeout(menu_timeout);

                mainmenu.addClass("actif");
            },
            touchend: function() {
                menuItemClose();
            }
        });

    $(".menu_itm_hover").on({
        mouseenter: function(evt) {
            var mainmenu = $(".main_menu");
            var submenu = $(".submenu_wrapper", this);

            evt.preventDefault();

            clearTimeout(menu_timeout);

            if (!mainmenu.hasClass("actif")) {
                $("#shad_menu").addClass("actif from_main_menu");
                $(this).addClass('hovering');
                $('#site_head_wrap').addClass('with_shad');

                mainmenu.addClass("actif");

                menu_timeout = setTimeout(function () {
                    submenu.stop(true, true).fadeIn(200);
                }, 200);
            } else {
                submenu.show();
            }
        },
    });

    function menuItemClose(submenu) {
        clearTimeout(menu_timeout);

        if (submenu) {
            submenu.stop(true, true).fadeOut(300);
        }

        $('#shad_menu').fadeOut(100);

        menu_timeout = setTimeout(function() {
            $("#shad_menu").removeClass("actif");
            $(".main_menu").removeClass("actif");
            if($('#shad_menu.from_main_menu').length) {
                $("#shad_menu").removeClass("from_main_menu");
            }
            if ($('#site_head_wrap.with_shad').length) {
                $('#site_head_wrap').removeClass('with_shad');
            }
        });
    }

    $(document).on("scroll", function() {
        menuItemClose($(".submenu_wrapper:visible"));
    });

    $(".menu_itm_hover").on("mouseleave", function() {
        menuItemClose($(".submenu_wrapper", this));
        $(this).removeClass('hovering');
        $('.main_menu').removeClass('actif');
    });

    $('#trigger_recherche').on("click touch", function() {
        $('.wrap_search_field').fadeIn().css("display", "flex");
        $('#shad_menu').addClass('actif');
    });

    $('.close_search').on("click touch", function() {
        closeSearch();
    });

    $("#search_home").on('input', function() {
        var value = $("#search_home").val();

        if(value.length >= 3){
            $('body').addClass('search_in');
        } else {
            $('body').removeClass('search_in');
        }
    });

    if ($(".promo_swiper .swiper-slide").length > 1) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            loop: true,
            slidesPerView: 1,
            navigation: {
                nextEl: '.navpromo.promoNext',
                prevEl: '.navpromo.promoPrev',
            },
            speed: 1500,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            autoplayDisableOnInteraction: false
        });
        // show navigation
        $('.navpromo.promoNext,.navpromo.promoPrev').show();
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
            $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            $('.misc_cookiesinfo #shadCookie').removeClass('actif');
        }
    });
});

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartAppear() {
    var show_top_cart_parent = $('.my_basket');
    var count_bask = $('#cart_top .cart').attr('data-cart');

    if(count_bask > 2){
        $("#cart_list", "#cart_top").overlayScrollbars({});
    } else if (count_bask == 0) {

        if ($(window).height() < 720 ) {
            $(".no_product", ".cart_box.noprod").overlayScrollbars({});
        }

    }

    show_top_cart_parent.addClass("hover");
    $('#shad_menu').addClass("actif");
}

function btnLoaderTrigger(evt) {
    var loader = $(this).next(".loader");

    if (loader.length > 0) {

        // Disable prevent.default on form in order to allow the submit
        if (!$(this).parents('form').length) {
            evt.preventDefault();
        }

        loader
            .removeAttr("style")
            .parent()
            .addClass("loading");

        var href = $(this).attr("href");
        if (href && href != '#' && href != 'javascript:void(0)') {
            window.location.assign($(this).attr("href"));
        }
    }
}

$(function() {
    $(document).on("click", ".button", btnLoaderTrigger);
});

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartDesappear() {
    var show_top_cart_parent = $('.my_basket');
    show_top_cart_parent.removeClass("hover");
    $('#shad_menu').removeClass("actif");
}

function closeRecherche(){
    if ($('.block_top_item.search').hasClass('hovering')) {
        $('.block_top_item.search').removeClass('hovering');
        $('#shad_menu').removeClass('actif');
    }
}

$('#search_home').keypress(function (e) {
    var key = e.which;

    if(key == 13 && $('#search_home').val().length >= 3) {
        closeSearch();
    }
});

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function(elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function() {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    // Simpler way doesn't seem to work well with lazyloader
    // return document.getElementById(field).src = path;
    var productSwiper = new Swiper(".productSwiper", {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
        preloadImages: false,
        lazy: isLazyLoad,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });

}

var main_product_select_html = $('.product_infos_form.not_achat_express #product_selects').html();

function closeMultiShad(id) {
    $('#shad').off('click');
    $("#availabilityInStore").slideUp(600);
    
    $('#' + id).fadeOut("slow").queue(function () {
        if ($('#shad').is(":visible")) {
            if (id === 'popup-fairlymade') {
                $('#shad').fadeTo("fast", 0, function () {
                    $('#shad').css('display', 'none');
                });
            } else {
                $('#shad').fadeTo("slow", 0, function () {
                    $('#shad').css('display', 'none');
                });
            }
        }

        if ($('#shad_abox').is(":visible")) {
            $('#shad_abox').fadeTo("slow", 0, function () {
                $('#shad_abox').css('display', 'none');
            });
        }

        $(this).dequeue();
    });

    if ( $('.product_infos_form.not_achat_express #product_selects').html() == '' ) {
        $('.product_infos_form.not_achat_express #product_selects').html(main_product_select_html);
    }

    if (id == "sendwishlistbox") {
        $("#sendwishlistbox").hide();
        $('#shad').css('opacity', '0');
    }

    if (id === 'popup-fairlymade') {
        $("#productInfos").removeClass("active");

        setTimeout(() => {
            $('body').css('overflow','scroll');
        }, 600);
    }
}

function paginate(page) {
    var form = document.getElementById('filter_sticky'),
        total_element = form.getValue('totalElems', parseFloat),
        nb_prod = form.getValue('nb_prod', parseFloat),
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $('.pager_wrapper'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        see_all = form.getValue('see_all', parseFloat);

    page = page || 1;

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {
            var productLoaded = $(".productBigPictures").length;

            pagination.each(function () {
                if (nb_prod != 1000 && nb_prod < total_element) {
                    this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('nbProdDisplayed', productLoaded, total_element) + '</div><div class="loadedGauge"><progress value="' + productLoaded + '" max="' + total_element + '"></progress></div>';
                    
                } else if (total_element > nb_prod_default) {
                    this.innerHTML = '<div class="nbProdDisplayed">'+ Translator.translate('nbProdDisplayed', productLoaded, total_element) + '</div><div class="loadedGauge"><progress value="' + productLoaded + '" max="' + total_element + '"></progress></div>';
                    
                } else {
                    this.innerHTML = '';
                }
            });
        }
    });
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop, fromPagination) {
    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {
        generateNewBlocSearch(page_bloc);
    } else {
        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, fromPagination);
    }
}

var bloc_prod_xhr;

//Uilisée pour afficher les produits
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || parseFloat($("#nb_prod_default").val()),
        output = {};

    // Add misc values to output object
    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky', false);

    if (fromScrollTop || fromScrollBottom) {
        $('#initial_page').val(page + 1);
        seeAllBtnClick("remove");
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    if (fromPagination) {
        output.page = $(".productBigPictures").length;
    }

    $('.loader_scroll').addClass('loading');
    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_reload_products.php',
        type: 'get',
        data: output,
        success: function (res) {
            var old_elm = document.getElementById('list_item'),
                content, itm_length;

            if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {
                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');
            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + canonical);

            $("link[rel^='alternate'][hreflang^='x-default']").attr("href", JSON.parse(res[8])[1].en);
            $("link[rel^='alternate'][hreflang^='en-gb']").attr("href", JSON.parse(res[8])[2].en);
            $("link[rel^='alternate'][hreflang^='en-us']").attr("href", JSON.parse(res[8])[3].en);
            $("link[rel^='alternate'][hreflang^='en-ch']").attr("href", JSON.parse(res[8])[4].en);

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
            changeSeoElement(next, hrefNext,commentNext, 'next');

            if (content !== '') {
                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".autoLoad").fadeTo("fast", 0);
                    
                } else {
                    old_elm.innerHTML = "";
                }

                document.getElementById('totalElems').value = itm_length;
                old_elm.insertAdjacentHTML('beforeend', content);

                $(".holder_submits button:first > span").html(Translator.translate('see_x_results', itm_length));
                
                if (itm_length > 1) {
                    $(".article_number").html(itm_length + " " + Translator.translate('items'));
                    
                } else {
                    $(".article_number").html(itm_length + " " + Translator.translate('item'));
                }
                
                if (!fromPagination && $("body").hasClass("category")) {
                    $(".wrap_bg .push.item").remove();
                    $(".holder_topProduct .item").remove();
                    var newPush = $("#list_item #push_rayon_3");
                    $(".wrap_bg").append(newPush);
                    var newPushProduct = $("#list_item .wrapperProdVertical:first");
                    $(".holder_topProduct").append(newPushProduct);
                }

                if (window.lazyload !== undefined) {
                    lazyload.init();
                }
            }
        },
        complete: function () {
            // force trigger on input hidden to fire the change() event
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');

            if (typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false) {
                if (enable_gbe_sfs == false) {     // + sfs not eanbled for GMB
                    $('.no_stock_entrepot').each(function () {
                        if (!$(this).hasClass('no_stock')) {
                            $(this).addClass('no_stock');
                        }
                    });
                }
            }

            if (fromScrollTop || fromScrollBottom) {
                seeAllBtnClick("add");
            }

            if (!fromScrollBottom && !fromScrollTop) {
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: 0
                    }, 200);
                }, 250);
                
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
            }

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');
            }

            if (fromScrollBottom) {
                $("#initial_page").val(page +1);
            }

            /**
            * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
            */
            var productLoaded = $(".productBigPictures").length;
            var total_element = document.getElementById('totalElems').value;

            $('.nbProdDisplayed').html(Translator.translate('nbProdDisplayed', productLoaded, total_element));
            $('.txt_bandeau span').html(total_element + ' ' + Translator.translate('2_product'));
            $('.loadedGauge').html('<progress value="' + productLoaded + '" max="' + total_element + '"></progress>');
            $('.loader_scroll').removeClass('loading');

            if(productLoaded >= total_element) {
                $('.loader_scroll').hide();
            }
        }
    });
}

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type("orders_by_type", order_type, ROOT_PATH))
        $("#order_" + order_id).click();
    $("html, body").animate(
        {
            scrollTop: $('[data-btn-name="order_section"]').offset().top - 100,
        },
        500
    );
}

function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + "ajax_get_uri_by_lang.php",
        type: "post",
        data: {lang: locale, url: uri, site: site},
        success: function(data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        },
    });
}

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );
                is_disabled = !sizes_tab[size_id];
            }
        }
    }
}

$(".bloc_lightbox_preco").on("click", function() {
    if (!$(this).hasClass("actif")) {
        $(".bloc_lightbox_preco.actif").removeClass("actif");
        $(this).addClass("actif");
    }
});

$.fn.initAssocBloc = function(options) {
    var options = $.extend(
        {
            numberItem: 3,
        },
        options
    );
    var element = $(this);

    function uniq(a) {
        return a.sort().filter(function(item, pos, ary) {
            return !pos || item != ary[pos - 1];
        });
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function reSortInnerElem(elem, sortArr) {
        var i = 0;
        var returnList = [];
        $("div.alt_sld_view", elem).each(function(index) {
            if (i >= sortArr.length) {
                i = 0;
            }

            var tempList = [];
            var j = 0;
            $("div.alt_sld_view", elem).each(function() {
                if ($(this).attr("data-emplacement") == sortArr[i]) {
                    tempList[j] = $(this).prop("outerHTML");
                    j++;
                }
            });

            var randomNum = getRandomInt(0, tempList.length - 1);
            returnList[index] = tempList[randomNum];
            i++;
        });
        return returnList;
    }

    function displayItem(eleme) {
        var element = eleme;
        var sortList = [];
        var list = [];
        var innerList = "";

        $("div.alt_sld_view", element).each(function(i, li) {
            sortList[i] = $(this).attr("data-emplacement");
        });
        
        sortList = uniq(sortList);
        list = reSortInnerElem(element, sortList);
        
        for (var i = 0; i < options["numberItem"]; i++) {
            innerList += list[i];
        }

        element.html(innerList);

        return true;
    }

    return displayItem(element);
};

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;

        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else {
            window.history.back();
        }
    }
}

//Utilisé pour le scroll
function fix_rayon_display() {
    if ($("#list_item .item").length > 0) {
        var items = $("#list_item .item");
        var itemCount = $("#list_item .item").length;

        if (itemCount % 3 == 2){
            $("<div class=\"item empty\"></div>").insertAfter(items.last());
        }
    }
}

$('body').on('click','.search_recap',function(){
    $('#trigger_recherche').click();
    $('#search_home').focus();
});

if($(".productSwiper").length) {
    $('body').on('mouseenter touchstart','.productSwiper',function(){
        if($(this).find('.swiper-slide-on').hasClass('cache')) {
            $(this).find('.swiper-wrapper').html($(this).find('.swiper-slide-on').html());
            $(this).find('.swiper-slide-off').remove();
            $(this).find('.swiper-slide-on').remove();

            var productSwiper = new Swiper($(this), {
                slidesPerView: 1,
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                },
                preloadImages: false,
                lazy: isLazyLoad,
                loadPrevNext: true,
                loadOnTransitionStart: true
            });

        }

    });

    //Ajout d'une min-height pour éviter l'effet clignotement
    if(!$('.rayon_lookbook').length) {
        if($(".swiper-slide-off").find("span").hasClass('ill_img')){
            $("#list_item").find($(".item")).css("min-height", "276px");
        }
    }
};

$(function () {
    var $deja_compte = Translator.translate('existing_user_pwd_reset_' + site_id);
    $( ".login_form_wrapper .w-newlogin-form" ).append( $('<p class="deja_compte">' + $deja_compte + '</p>') );
    $(".body_login #site_global_wrap .wrapper_bandeau_connexion").parent('div').addClass('vp_login_special');
    $(".vp_login_special").parent('section').addClass('vp_login_container');

    window.setTimeout(function () {
        //add class to tilli widget for page nos-services
        if ($(".satellite_nos_services .nos_services .rootTilliPlugin").length > 0){
            $(".rootTilliPlugin.cleanslate").addClass('underline');
        }
    }, 300);
});

function openMultiShad(id) {
    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {
        $("#" + id).slideDown(600);
        if($("#" + id).hasClass('closed')) {
            $("#" + id).removeClass('closed');
        }
    });

    if (id == 'lightbox_achat_express') {
        $("#" + id).css("transform", "translateX(0)");
    }

    if (id == 'popup_cofidis3cb') {
        // COFIDIS FP
        if ($('#popup_cofidis3cb .popup_container').length) {
            $('#popup_cofidis3cb .popup_container').overlayScrollbars({});
        }
    }

    if (id === 'selection-offer') {
        setTimeout(function (){
            $('#selection-offer-content').overlayScrollbars({});
        }, 250);
    }

    if (id == 'popup_klarna_3cb') {
        // KLARNA FP
        if ($('#popup_klarna_3cb .popup_container').length) {
            $('#popup_klarna_3cb .popup_container').overlayScrollbars({});
        }
    }

    if (id !== 'zoomBox') {
        $("#shad").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id === "zoomBox") {

        $('#zoom_box_wrapper .swiper-slide').mousemove(function (event) {

            var slideOffset = $(this).offset();
            var relY = -1 * (event.pageY - slideOffset.top);

            $(this).find('img').css('top', relY + 100 + "px");
        });

        $('#zoom_box_wrapper .swiper-slide').on('click', function () {

            closeMultiShad('zoomBox');
        });
    }

    if ($("#buyNowSwiper").length > 0) {

        window.setTimeout(function () {
            buyNowSwiper = new Swiper("#buyNowSwiper", {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }, 50);
    }

    if(id === "sizeguideBox"){
        window.setTimeout(function () {
            $("#wrap_itable").overlayScrollbars({});
        }, 300);

        $('body').css('position','fixed');
    }

    // Shipup Lightbox
    if (id === "shipup-container") {

        // We check if theres the top-navbar and we add a close button to the lightbox
        if ($('.shipup-top-navbar', "#" + id).length && !$('.custom_shipup_close', "#" + id + " .shipup-top-navbar").length) {
            setTimeout(function() {
                $('.shipup-top-navbar', "#" + id).append('<div class="custom_shipup_close" onclick="closeMultiShad(\'' + id + '\')"><span class="bfr bgSprite cross"></span></div>');
            }, 500);
        }

        setTimeout(function() {
            $("#" + id).find('.shipup-home-container').overlayScrollbars({
                scrollbars: {
                    autoHide: "leave",
                    autoHideDelay: 0,
                }
            });
        }, 500);
    }

    if (id === 'popup-fairlymade') {
        $("#productInfos").addClass("active");
        $('body').css('overflow','hidden');
    }
}

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    
    $shad.attr("onclick", "");
    $shad.animate({opacity: 0}, 350, function() {
        $shad.css({display: "none", opacity: ""}).data("opened_lb", 0);
    });
    
    $modbox.css({display: "none"});
}

var afficheLostPassword = function (evt) {
    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {
        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

if($('#completeLookTitle').length === 0){
    $("#mightLikeTitle").addClass("is-active");
    $(".associations_by_rayon").removeClass("is-hidden");
};

function checkSelectedInput(){
    var inputTaille = $('.product_infos_form.not_achat_express').find('#tailleProd');
    if (inputTaille.length && inputTaille.val() == '') {
        $('#sizeerror').slideDown();
    }
}

/***** PROD ASSOCS DATA LAYERS *****/
function dataLayer_associated_product () {
    if (window.$('#gondole_aimerez')) {
        var product_id = this.getAttribute('data-prod') ? event.currentTarget.getAttribute('data-prod') : '';
        if (product_id !== '') {

            data_layer_products.forEach(function(item){
                if ( item.id == product_id ) {
                    productObj = item;
                }
            })
            productObj.url = $(this).children("a").attr("href");
            var prod = productObj;
            var product_link = prod !== undefined ? prod.list : '';
            delete prod.ean;
            delete prod.ref;
            delete prod.sku;
            delete prod.refSizeColor;

            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list':product_link},      // Optional list property.
                        'products': [prod]
                    }
                },
                'eventCallback': "function () {document.location = productObj.url}"
            });
        }
    }
}

function btnLoaderUnloading(btn) {
    "use strict";
    var loader = btn.next(".loader");

    if (loader.length > 0) {
        $('#shad_abox, .close_pop.close').on("click touch", function() {
            loader.removeAttr("style").parent().removeClass("loading");
        });
    }
}

function clickOnFilter(elem){
    var liElem = elem.parentElement;
    var ulElem = liElem.parentElement;
    var containerElem = ulElem.parentElement;
    var buttonFilter = containerElem.querySelector('p.nrc_button_filter');
    var textLiSelected = buttonFilter.querySelector('span.text_li_selected');
    var curentLiSelected = ulElem.querySelector('li.select');

    curentLiSelected.classList.remove('select');
    liElem.classList.add('select');
    textLiSelected.innerText = elem.innerText;

}

var containerFilter = document.querySelectorAll("#nrc_comments .nrc_filter");
if (containerFilter.length > 0){
    var transitOn = false
    window.addEventListener('load', () => {
        $("#lightbox_netreviews_comments").overlayScrollbars({});
        document.querySelector(".see_more_reviews").addEventListener("click", function(){
            containerFilter.querySelector("ul").style.display = "none";
        })
    });
    containerFilter = containerFilter[0];
    containerFilter.querySelector(".nrc_button_filter").addEventListener("mouseenter", function(){
        containerFilter.querySelector("ul").style.display = "block";
        if (!transitOn) {
            containerFilter.classList.add("hover_on");
            setTimeout(function(){
                transitOn = false
            }, 300);
        }
    });

    containerFilter.addEventListener("mouseleave", function(){
        if (!transitOn) {
            containerFilter.classList.remove("hover_on");
            transitOn = true
            setTimeout(function(){
                transitOn = false
            }, 300);
        }
    });
}

function verif_telephone( elementID, fieldname, is_mobile ) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                            fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}

// remove empty menu
function removeSpace() {
    let space = document.querySelectorAll( '.sitemap_section' );
    space.forEach( (item) => {
        if ( item.childNodes.length === 0 ) {
            item.style.display = "none";
        }
    })
};

 // removing the class big items after default number of product
function removeBigItemsClass() {
    setTimeout(function () {
        var itemContainer = document.getElementById("list_item");
        var items = itemContainer.querySelectorAll(".item");
        var maxItemsWithBigClass = 24;

        for (var i = 0; i < items.length; i++) {
            if (i < maxItemsWithBigClass) {
                doNothing;
            } else {
                let elem = document.querySelector('div.item');

                document.querySelectorAll('div').forEach(function (elem) {
                    elem.classList.remove("big_item");
                });
            }
        }
    }, 150);
}

if ($('#list_item').length && $('.item').length) {
    removeBigItemsClass();
}

removeSpace();

$(document).ready(function () {
    if (typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false) {
        $('.disable_globale').each(function () {
            if (!$(this).hasClass('disabled')) {
                $(this).addClass('disabled');
            }
        });
    }

    // add class qte_position on panier to correct qte position
    updateStyles();
});

// add class qte_position on panier to correct qte position
function updateStyles() {
    $('.product_line_basket.cart_product_line').each(function() {
        if ($(this).find('.line_product_desc_preorder').length > 0) {
            $(this).find('.product_quantity_change').addClass('qte_position');
        }
    });
}


// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});