// Simulate global-e environment. COMMENT THESE FIVE CLASSES WHEN NOT TESTING IN LOCAL
/*class GSetting {
    constructor () {
        this.IsOperatedByGlobalE = true;
    }
}
class GLocalize {
    constructor () {
        this.UserCountry = 'IE';
    }
}

class GInstance {
    constructor () {
        this.UserLocalizeSettings = new GSetting();
        this.LocalizeConfigurations = new GLocalize();
    }
}

class Gconf {
    constructor () {
        this.Instance = new GInstance();
    }
}

class GEM_Services {
    static ConfigurationsService = new Gconf();
}*/

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");
// Récupération de la valeur de la constante NO_LAZYLOAD pour appliquer le paramètre aux swiper
var isLazyLoad = (typeof is_lazy_load !== 'undefined' && is_lazy_load==false) ? false : true;

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

// Alters item quantity in cart
function changeQte(type) {

    var types = [ 'plus', 'less' ],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url : path_relative_root + create_link('order_basket'),
            type : 'post',
            data : {
                action : 'del',
                panierId : item,
                cad  : 'non',
                pointcad : 0,
                qteProd : qtty,
                change_qte : true
            },
            cache : false,
            success : function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc+=1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;

                cart = Translator.translate('mon_panier');
                var total = $('#cart_top .cart').attr('data-cart'),
                    text;

                if(total <= 1){
                    text = Translator.translate('one_product');
                } else {
                    text = Translator.translate('2_product');
                };

                $('.cart_main_title.basket').html(cart +' '+ '<span class="nb_products">(' + total + ' ' + text + ')</span>');

                if (dataLayer !== undefined ) {
                    dataLayer.push({
                        'event': 'basketUpdate',

                    });
                }


                // Reinit swiper on panier product quantity change
                if ($('.elem_tunnel .prod_reassurance_swiper').length) {
                    setTimeout(function () {
                        new Swiper('.elem_tunnel .prod_reassurance_swiper', {
                            slidesPerView: 'auto',
                            loop: true,
                            speed: 4000,
                            freeMode: true,
                            autoplay: {
                                delay: 1,
                                disableOnInteraction: false,
                            },
                        });
                    }, 500);
                }

                if ($('#faqTunnelList').length) {
                    accordionDropdown('#faqTunnelList li .faq_tunnel_question', '.faq_tunnel_answer', '#faqTunnelList li');
                }

                // add class qte_position on panier to correct qte position
                updateStyles();
            }
        });
    }
}

// loader_paypal
$(".wrapper_bloc_banque.paypal").on("click touch tap", function() {
    $( this ).find(".bank_subtitle").addClass('loader_paypal');
});

// Reload product from ref
function loadProduct(new_id, type, page, search) {

    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var cpt = this.getValue('cpt');

    var is_achat_express = $('#is_achat_express', this).val();
    var act = this.getValue('act');
    var qte_itm = 1;
    if (this.elements.namedItem('qteProd'))
        var qte_itm = this.elements.namedItem('qteProd').value;
    var is_model = this.getValue('hdn_is_model');
    var data = {
        produit_id: old_id,
        produit_principal: old_id,
        couleurProd: col_id,
        tailleProd: sze_id,
        act: act,
        qteProd: qte_itm,
        hdn_product_id: old_id,
        hdn_is_model: is_model,
        produit_id_new: new_id,
        type: type,
        type_page: (page !== undefined && page !== 'rayon') ? page : 'product',
        is_achat_express: is_achat_express,
        is_achat_express_v2 : (page == 'rayon') ? 1 : 0,
        cpt_prod : cpt,
        search : (search ? true : false)
    };

    if (document.getElementById('wishlist')) {
        data.wishlist = true;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        beforeSend: function () {

        },
        success: function (res) {

            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {

                var datas = res.datas;


                var $html_main = $(stripCombo(datas.html_main));

                if (page == 'rayon') {

                    // On scroll d'1px pour déclancher le lazy load et afficher l'image lors du rechargement du produit
                    window.scrollTo(0, $(window).scrollTop() + 1);

                    var prod_elem =  $(form).closest('.item');

                    // Handle big_items on view by three
                    if (prod_elem.hasClass('big_item')) {
                        var new_item = $html_main.addClass('big_item');

                        //Change src in swiper slides (will init itself on mouseenter)
                        if ($('.swiper-slide-on', new_item).length > 0) {
                            /* Here we're in color change case from Rayon/Categ/Boutique page *****/
                            $('.swiper-slide-on .swiper-slide', new_item).each(function() {
                                var img = $(this).find('img');
                                var swiper_preloader = $(this).find('.swiper-lazy-preloader');

                                img.attr('data-src', img.attr('data-src').replace("/medium/", "/prodfp/"));
                                // Don't forgert lazypreloader background image src too
                                swiper_preloader.attr('style', swiper_preloader.attr('style').replace("/medium/", "/prodfp/"));
                            });

                            // Change fake swiper img src
                            $('.swiper-slide-off .imgProd', new_item).attr(
                                'data-src',
                                $('.swiper-slide-off .imgProd', new_item).attr('data-src').replace("/medium/", "/prodfp/")
                            );
                        } else {
                            /* Here we're in color change case from Search page *****/
                            // replace format for each img in search result (vertical product : this->fromSearchPage)
                            new_item.find('.ill_img.imgProd').each(function() {
                                $(this).attr('src', $(this).attr('src').replace("/medium/", "/prodfp/"));
                            })
                        }

                        // Replace in DOM
                        prod_elem.replaceWith(new_item);
                    } else {
                        // Replace in DOM
                        prod_elem.replaceWith($html_main);
                    }

                    $('body').on('mouseenter touchstart', '.productSwiper', function () {

                        if ($(this).find('.swiper-slide-on').hasClass('cache')) {

                            $(this).find('.swiper-wrapper').html($(this).find('.swiper-slide-on').html());
                            $(this).find('.swiper-slide-off').remove();
                            $(this).find('.swiper-slide-on').remove();

                            var productSwiper = new Swiper($(this), {
                                slidesPerView: 1,
                                observer: true,
                                observeParents: true,
                                navigation: {
                                    nextEl: '.swiper-next',
                                    prevEl: '.swiper-prev',
                                },
                                preloadImages: false,
                                lazy: isLazyLoad,
                                loadPrevNext: true,
                                loadOnTransitionStart: true
                            });

                        }

                    });

                    $(form).find('.input.prodColor').prop('checked', false);
                    $(form).find('input.color_' + new_id + '_' + data.couleurProd).prop('checked', true);
                } else if (data.type_page == 'product') {

                    var $html_middle = $(stripCombo(datas.html_middle));

                    var color = $("#ligne_couleur").html();

                    var taille = $("#ligne_pointure").html();

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var product_refext = $('#product_refext', $html_main).val();
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);
                    var avisVerifiesReviews = $('w-netreviews-comments', $html_main);
                    var zoom_box_wrapper = $('#zoom_box_wrapper', $html_main);

                    var size_wrapper = $('#ligne_pointure', $html_middle);

                    var contents = $('<div />').html($html_main);

                    var video = $('.wrapper_video', contents);

                    if (data.is_achat_express) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());
                        // Bloc taille
                        $('#ligne_pointure', '#lightbox_achat_express').html(size_wrapper.html());
                    } else {

                        var assoc_wrapper = $('.associations_section', $html_main);

                        var description_wrapper = $('.content_desc_studio', $html_main);

                        var cms_page_wrapper = $('.cms_page_product', $html_main);

                        // Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());

                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                        // Descrirption studio
                        $('.content_desc_studio', '#site_global_wrap').html(description_wrapper.html());

                        // Cms page module
                        $('.cms_page_product', '#site_global_wrap').html(cms_page_wrapper.html());

                        // Produits associés
                        $('.associations_section', '#site_global_wrap').html(assoc_wrapper.html());

                        // Bloc taille
                        $('#ligne_pointure', '#site_global_wrap').html(size_wrapper.html());

                        //zoom box
                        $('#zoom_box_wrapper').html(zoom_box_wrapper.html());
                    }

                    /* NETREVIEWS 
                        First switch, ID is in DOM, replace by ID
                        After the first switch replace by tag name
                    */
                    if ($("w-netreviews-comments").length > 0) {
                        $("w-netreviews-comments", '#site_global_wrap').replaceWith(avisVerifiesReviews.wrap('<p/>').parent().html());
                    } else {
                        $("#w-netreviews-comments", '#site_global_wrap').replaceWith(avisVerifiesReviews.wrap('<p/>').parent().html());
                    }
                    // Reload vuejs netreviews
                    if (typeof wShop !== 'undefined') {
                        // Netreviews stars
                        if (wShop.$refs.wNetreviewsStars != 'undefined') {
                            wShop.$refs.wNetreviewsStars.reload(product_refext);
                        }
                        // Netreviews reviews
                        if (wShop.$refs.wNetreviewsComments != 'undefined') {
                            wShop.$refs.wNetreviewsComments.reload(product_refext);
                        }
                    }

                    $("#ligne_couleur").html(color);
                    $('input.color').prop('checked', false);
                    $('input#color_' + data.couleurProd).prop('checked', true);
                    $('input.size').prop('checked', false);
                    if ($("#buyNowSwiper").length > 0) {
                        var buyNowSwiper = document.getElementById("buyNowSwiper");

                        if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {

                            buyNowSwiper = new Swiper("#buyNowSwiper", {
                                sliderPerView: 1,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }
                    }

                } else if (type_page == 'look') {

                    var $html_middle = $(stripCombo(datas.html_middle));

                    var color = $("#ligne_couleur").html();

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);

                    var contents = $('<div />').html($html_main);

                    // Photo et information
                    $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                    // Description compo, livraison, guide tailles
                    $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());

                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');
                    $('#itm_thumb_list').empty();
                    var thumbnails = loadPrevisu();
                    parseThumbList(thumbnails);

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {

                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id, datas.product.id, path_relative_root);

                }

                json_colisages = res.stock;
                // Define sizes that still have stock
                setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));

                // we check if we have stock on this product
                let sizes_tab = json_colisages[$('#prod_info_' + datas.product.id + ' input[name="itm_color"]:checked').val()];
                let has_stock = false;
                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        if (sizes_tab[size_id]) {
                            has_stock = true;
                            $("#size_"+size_id).removeClass('disabled');
                        } else {
                            $("#size_"+size_id).addClass('disabled');
                        }
                    }
                }

                if (has_stock) {
                    $("#bloc_add_basket").show();
                    $("#bloc_add_alert").hide();
                    $('.product_images').removeClass('no_stock');
                } else {
                    $("#bloc_add_basket").hide();
                    $("#bloc_add_alert").show();
                    $('.product_images').addClass('no_stock');
                }

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {

            var heads = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function (event) {
                event.preventDefault();

                var element = $(this);
                var tab_number = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {
                if($("#productInfos .prod_reassurance_swiper").length > 0) {
                    var prodReassuranceSwiper = new Swiper('#productInfos .prod_reassurance_swiper', {
                        slidesPerView: 'auto',
                        loop: true,
                        speed: 4000,
                        freeMode: true,
                        autoplay: {
                        delay: 1,
                        disableOnInteraction: false,
                        },
                    });
                }

                function stickyRightPart() {
                    var sticky = this.document.getElementById(
                        "productRightPart"
                    );

                    this.removeEventListener("load", stickyRightPart);

                    if (sticky) {
                        sticky = new ScrollLock("productRightPart");
                    }

                    return sticky || false;
                }

                var more_vis = $('.more_vis');
                $(window).on('resize', function () {

                    more_vis.each(function () {

                        var jqzoom = $(this).next('#jqzoom');

                        if (jqzoom.length > 0) {

                            $(this).height(jqzoom.height());
                        }
                    });
                });

                setTimeout(function () {
                    checkSubmitState();
                    eventButton();
                }, 500);
            }, 250);

            heads.each(function () {
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
            var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

            // We don't need to check if there is stock (Change the color of the product in rayon)
            if (page !== 'rayon') {
                getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd, '', data.couleurProd);
            }

            if ($("#gondole_portez").length > 0) {

                $('#associations_slider').initAssocBloc();

                var items = $(".associations .assoc");
                var nb_items = items.length;

                $(".associations .assoc").each(function (index) {
                    $(this).removeClass('swiper-slide');
                });

                var item_per_div = 1;
                for (var i = 0; i < nb_items; i += item_per_div) {
                    items.filter(':eq(' + i + '),:lt(' + (i + item_per_div) + '):gt(' + i + ')').wrapAll('<div class="swiper-slide" />');
                }

                assoSwiper = new Swiper(".swiper-container.associations", {
                    slidesOffsetBefore: 0,
                    centeredSlides: false,
                    slidesPerView: 3,
                    spaceBetween: 30,
                    loop: true,
                    navigation: {
                        nextEl: '.swiper-btn.swiper-button-next',
                        prevEl: '.swiper-btn.swiper-button-prev',
                    }
                });

            }

            if ($("#product_associations_swiper .swiper-slide").length > 2) {

                window.setTimeout(function () {
                    var productswiper = new Swiper('#product_associations_swiper', {

                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 50,
                        loop: true,

                        navigation: {
                            nextEl: '.swiper-button-next.product_btn_nxt',
                            prevEl: '.swiper-button-prev.product_btn_prv',
                        },

                        breakpoints: {
                            1160: {
                                slidesPerView: 1,
                                slidesPerGroup: 1,
                                spaceBetween: 50,
                                loop: true,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                                loop: true,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                                loop: true,
                            },
                            320: {
                                slidesPerView: 1,
                                slidesPerGroup: 1,
                                spaceBetween: 10,
                                loop: true,
                            }
                        },

                    }, 200);
                });
            }

            /***** OTHER FUNCTIONS *****/

            /** FP FEATS WRAPPER */
            if ($('#prodFeatsWrapperAccordion').length) {
                accordionDropdown('#prodFeatsWrapperAccordion li .title', '.content', '#prodFeatsWrapperAccordion li');
            }

            /** FP ASSOCIATIONS TOGGLE */
            if ($('.associations_section').length) {
                $('.associations_section h2').on('click', function () {
                    $('.associations_section h2').toggleClass('is-active', '');


                    if ($(this).is('#mightLikeTitle')) {
                        $('.associations_section .product_associations_wrapper.associations_by_produit').hide();
                        $('.associations_section .product_associations_wrapper.associations_by_rayon').show('fade');
                    } if ($(this).is('#completeLookTitle')) {
                        $('.associations_section .product_associations_wrapper.associations_by_rayon').hide();
                        $('.associations_section .product_associations_wrapper.associations_by_produit').show('fade');
                    }
                    gondolLookSwiper.update();
                });
            }

            if (page !== 'rayon') {
                checkProductInWishlist.call(form);
            }

            if (document.getElementById('wishlist')) {
                // event sur le bouton d'ajout à la wishlist
                $("#product_addcart").appendTo('.product_main_wrapper');
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            // PRODUCT SWIPERS
            setTimeout(function () {
                if($("#visual_scroller .swiper-slide").length) {
                    var productThumbs = new Swiper('#visual_scroller', {
                        slidesPerView: 4,
                        spaceBetween: 1,
                        direction: 'vertical',
                        loop: false,
                        navigation: {
                            nextEl: '.thumbNext',
                            prevEl: '.thumbPrev',
                        },
                    });
                } else {
                    $("#visual_scroller_wrapper").addClass('no_swiper');
                }

                if($("#jqzoom .swiper-slide").length > 1) {
                    //animation to avoid slide's width transition on pageload
                    $('#jqzoom').animate({
                        opacity: 1,
                    },10);
                    var main_productVisu = new Swiper('#jqzoom', {
                        loadOnTransitionStart: true,
                        direction: 'horizontal',
                        thumbs: {
                            swiper: productThumbs
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            type: "bullets",
                            clickable: true
                        },
                        slidesPerView: 1,
                        loop: false,
                    });
                }
            }, 250);

            //END PRODUCT SWIPERS

            if (typeof lazyload != 'undefinded') {
                lazyload.init();
            } else {
                window.scrollTo(0, $(window).scrollTop() + 1);
            }

            if (window.dataLayer !== undefined) {
                window.dataLayer.push({
                    'event': 'view_item',
                });
            }

        }
    });
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, from, search) {

    var control, prod_id, color_name;

    if (this.checked) {

        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        color_name = $(this).data("nom");
        prod_id = form.getValue('itm_id_' + control.value).trim();
        $(".chossen_color").html(color_name);

        if (is_regroup) {

            loadProduct.call(form, prod_id, 'couleur', from, search);
        } else {

            // On regarde si le produti est dejà dans la wishlist
            var in_wishlist = checkProductInWishlist.call(form);
            var img = $(this).data('pic');

            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

function onItemSizeChange(form) {

    var control;

    if ($('#sizeerror').is(':visible')) {

        $('#sizeerror').slideUp();
    }

    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();

    var id_principal = ($('#lightbox_achat_express').css('display') == 'block') ? $('#lightbox_achat_express #produit_principal').val() : $('#produit_principal').val();
    var id = form.getValue('produit_id');

    // En fonction de la couleur on regarde si le produit est disponible
    getAvailableQuantity(id_principal, $('#qteProd').val(), 'product');

    // On regarde si le produit est dejà dans la wishlist
    checkProductInWishlist(id);
}

// // Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var color_id = $(form).find("input[name=couleurProd]").val();
    var size_id = $(form).find("input[name=tailleProd]").val();
    var plnb = form.getValue('produit_lot_nb');

    id = ( (id !== undefined) ? id : (($('#lightbox_achat_express').css('display') == 'block') ? $('#lightbox_achat_express #produit_principal').val() : form.getValue('produit_id')) );

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }

    var data = {
        idprod: id,
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from == 'basket') {

        data.suff = id;
    } else {

        // Due to express buy, from == product -> check if body has tunnel basket classes
        if ($('body.cart.step_1').length > 0) {
            data.suff = id;
        }
    }

    if (plnb) {

        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_liste_quantity'),
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            try  {
                var isOperatedByGlobalE = false;
                if (typeof GEM_Services != "undefined" && typeof GEM_Services.ConfigurationsService != "undefined" && typeof GEM_Services.ConfigurationsService.Instance != "undefined" && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != "undefined") {
                    isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
                } else if (typeof GEP != 'undefined' && GEP.isOperatedByGlobale !== undefined) {
                    isOperatedByGlobalE = GEP.isOperatedByGlobale;
                }
            } catch (e) {
                console.log(e);
            }

            var achat_express_lightbox = $('#achat_express_lightbox').val();
            var lightboxAchatExpressVisible = $('#lightbox_achat_express').is(':visible');

            // Check if globale delivery country
            // OR is express checkout AND have no warehouse stock AND product not SFS
            if (res.disable_addtocart == true && ((typeof isOperatedByGlobalE !== 'undefined' && isOperatedByGlobalE !== false)
                || (is_achat_express && (typeof isOperatedByGlobalE == 'undefined' && typeof res.is_sfs !== "undefined" && res.is_sfs == false)))) {
                res.stock_tampon = 0;
            }

            if (res.stock_tampon == 0) {

                if (typeof achat_express_lightbox != "undefined" && achat_express_lightbox && lightboxAchatExpressVisible) {
                    if (!$("#lightbox_achat_express .w-alertestock-form").length) {
                        new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#lightbox_achat_express #keep_alive_stock');
                    }
                    $('#lightbox_achat_express .bloc_add_alert' + elem).show();
                    $('#lightbox_achat_express #bloc_add_basket').hide();
                } else {
                    if (wShop.$refs.wAlertestockForm) {
                        wShop.$refs.wAlertestockForm.reloadBloc += 1;
                    }

                    $("#prod_info" + elem + " .rollover_left").hide();
                    $("#preorderEclat").hide();

                    if (!is_achat_express) {
                        $("#bloc_add_basket").hide();
                    }

                    $('#lastpieceEclat').hide();

                    if (res.stock_quantite > 0) {
                        $(".bloc_another_basket" + elem).show();
                        $(".bloc_add_alert").hide();
                    } else {
                        $(".eclat_product_nouveaute_fiche_produit").show();
                        $(".product_images").addClass('no_stock');
                        $("#buyNowSwiper").addClass('no_stock');
                        $(".bloc_add_alert" + elem).show();
                        $(".bloc_another_basket").hide();

                        $('#ligne_qte').append($('.restock_date'));
                    }
                }
            } else {
                if (typeof achat_express_lightbox != "undefined" && achat_express_lightbox && lightboxAchatExpressVisible) {
                    $("#lightbox_achat_express .bloc_add_alert").hide();
                    $("#lightbox_achat_express #bloc_add_basket").show();
                } else {
                    $("#preorderEclat").show();
                    $(".product_images").removeClass('no_stock');
                    $("#buyNowSwiper").removeClass('no_stock');

                    if (res.stock_tampon > 0 && parseInt(res.stock_tampon) <= parseInt(alerte_last_stock)) {
                        $('#lastpieceEclat').show();
                    } else {
                        $('#lastpieceEclat').hide();
                    }

                    if (is_achat_express) {
                        waitingAddToBasket(event, form, '', itm_rr_id);

                    } else {
                        $(".eclat_product_nouveaute_fiche_produit").hide();
                        $(".bloc_add_alert").hide();
                        $(".bloc_another_basket").hide();
                        $("#bloc_add_basket").show();
                    }
                }
            };

            // Check if globale delivery country
            if (res.disable_addtocart == true){

                //global_e global param
                if (typeof GEM_Services != "undefined") {
                    var userCountry = GEM_Services.ConfigurationsService.Instance.LocalizeConfigurations.UserCountry;
                }

                if ((typeof userCountry !== "undefined" && userCountry.IsOperatedByGlobalE == true) || ((typeof userCountry === "undefined" || typeof userCountry !== "undefined" && userCountry.IsOperatedByGlobalE == false) && typeof res.is_sfs !== "undefined" && res.is_sfs == false))  {
                        $("#bloc_add_basket").hide();
                        $(".bloc_add_alert").show();
                        $(".bloc_another_basket").hide();
                        $("#productInfos #size_" + size_id).addClass('disabled');
                }
            }
        }
    });
}

// Hide pre-header when empty 
$(document).ready( function() {

    var preheaderWrapper = $('#site_head_wrap #header_mention_id');
    var siteGlobalWrapper = $('.bandeauDelivery .main_wrapper');

    if (preheaderWrapper.is(':empty')) {
        preheaderWrapper.hide();
        siteGlobalWrapper.addClass('preheader-hide');
    }
    else {
        preheaderWrapper.show();
        siteGlobalWrapper.removeClass('preheader-hide');
    }
});

$(document).ready(function () {
    if (document.readyState == 'complete' && typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false) {
        if (typeof enable_gbe_sfs == 'undefined' || typeof enable_gbe_sfs == '' || typeof enable_gbe_sfs == false ) {     // + sfs not eanbled for GMB
            $('.no_stock_entrepot').each(function () {
                if (!$(this).hasClass('no_stock')) {
                    $(this).addClass('no_stock');
                }
            });
        }
    }

    // create a fade-in effect on VP login page
    if ($('.body_login').length && $('.wrapper_tunnel').hasClass('vp')) {
        $('.newlogin_page').addClass('show');
    }
});